<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
			width="55%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<div
							class="form-group"
							:class="{ 'error--text': errors.name }"
						>
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<div
							class="form-group"
							:class="{ 'error--text': errors.unit_type_id }"
						>
							<label>Unidad de medidad</label>
							<el-select v-model="form.unit_type_id" filterable>
								<el-option
									v-for="(option, index) in unitTypes"
									:key="index"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.unit_type_id"
								v-text="errors.unit_type_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<div
							class="form-group"
							:class="{ 'error--text': errors.warehouse_id }"
						>
							<label>Almacén</label>
							<el-select v-model="form.warehouse_id">
								<el-option
									v-for="(option, index) in warehouses"
									:key="index"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.warehouse_id"
								v-text="errors.warehouse_id[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<div
							class="form-group"
							:class="{ 'error--text': errors.stock_control }"
						>
							<label class="control-label"
								>Control de stock</label
							>
							<el-select
								v-model="form.stock_control"
								dusk="stock_control"
							>
								<el-option
									:key="1"
									:value="true"
									label="Si"
								></el-option>
								<el-option
									:key="2"
									:value="false"
									label="No"
								></el-option>
							</el-select>
						</div>
					</v-col>
					<v-col
						cols="12"
						xl="4"
						lg="4"
						md="4"
						sm="4"
						v-if="form.stock_control && !form.id"
					>
						<div
							class="form-group"
							:class="{ 'error--text': errors.stock }"
						>
							<label>Stock inicial</label>
							<el-input
								v-model="form.stock"
								class="input-text-right"
							></el-input>
							<small
								class="error--text"
								v-if="errors.stock"
								v-text="errors.stock[0]"
							></small>
						</div>
					</v-col>
					<v-col
						cols="12"
						xl="4"
						lg="4"
						md="4"
						sm="4"
						v-if="form.stock_control && !form.id"
					>
						<div
							class="form-group"
							:class="{ 'error--text': errors.stock_min }"
						>
							<label>Stock mínimo</label>
							<el-input
								v-model="form.stock_min"
								class="input-text-right"
							></el-input>
							<small
								class="error--text"
								v-if="errors.stock_min"
								v-text="errors.stock_min[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<div class="form-actions text-right mt-3 mb-3">
					<el-button type="danger" @click.prevent="close()"
						>Cancelar</el-button
					>
					<el-button
						type="primary"
						native-type="submit"
						:loading="loading"
					>
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'ingredients',
			valid: false,
			unitTypes: [],
			warehouses: [],
			errors: {},
			form: {},
			rules: {
				name: [
					{
						required: true,
						message: 'El nombre es requerido',
						trigger: 'blur',
					},
				],
			},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				name: null,
				unit_type_id: 'NIU',
				stock_control: false,
				stock: 0,
				stock_min: 1,
			};
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar insumo' : 'Nuevo insumo';

			await this.initForm();
			await this.getTables();

			if (this.recordId) {
				this.$http
					.get(`/${this.resource}/record/${this.recordId}`)
					.then((response) => {
						this.form = response.data.data;
					});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.unitTypes = response.data.unitTypes;
				this.warehouses = response.data.warehouses;

				this.form.warehouse_id =
					this.warehouses.length > 0 ? this.warehouses[0].id : null;
				// this.form.unit_type_id = (this.unitTypes.length > 0)?this.unitTypes[0].id:null
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
